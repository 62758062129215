@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

.shopping-card__tooltip {
	z-index: 9999 !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;

	&--content {
		position: relative !important;
	}
}

.vehicle-type {
	&__all-divisions {
		align-items: flex-start;
	}
}

.product-page__action {
	height: 48px;

	@include lt-sm {
		width: 280px;
	}

	@include lt-xs {
		width: 180px;
	}

	@include lt-xxs {
		width: 100%;
	}
}

.extension-selection__title {
	margin-left: 0;
}
