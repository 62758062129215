@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

.product-gallery-container {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr 1fr;
	gap: calc(2 * #{$grid-spacing-medium});

	img {
		width: 100%;
		height: 250px;
		object-fit: contain;
		padding: 20px;
	}
}

.product-gallery-content {
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: flex-start;
}
