.product-page-highlights {
	display: flex;
	gap: 10px;
	margin-top: 40px;
	flex-direction: column;

	&__row {
		display: grid;
		gap: 10px;
		width: 100%;
	}

	&__row--1 {
		grid-template-columns: repeat(1, 1fr);
	}

	&__row--2 {
		grid-template-columns: repeat(2, 1fr);
	}

	&__row--3 {
		grid-template-columns: repeat(3, 1fr);
	}

	&__tile {
		background-color: #f8f8f8;
		padding: 20px;
		text-align: left;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}
