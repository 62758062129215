@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

.more-info-model {
	width: var(--modal-size-sm);
	max-height: calc(100vh - 50px);

	@include lt-xxs {
		width: 100%;
		align-self: flex-end;
	}

	&__image {
		p > img {
			@include lt-xxs {
				max-width: 100%;
			}
		}
	}
}
