@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

[data-mobile-only='true'] {
	@include gt-xxs {
		display: none;
	}
}

.diagnostic-solutions-wrapper {
	@include gt-xxs {
		transform: none !important;
	}
}

.diagnostic-solutions {
	grid-template-columns: subgrid;
	grid-column: span 3;
	gap: 10px;
	margin-top: 40px;

	.button {
		gap: 5px;
	}

	#diagnostic-solution-card > :nth-child(n + 4):nth-last-child(n + 2) {
		margin-bottom: 30px;
	}

	&__expand-button {
		padding: 10px;
		gap: 5px;

		&--left {
			content: ' ';
			flex: 1 1;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			border-bottom-color: inherit;
		}

		&--right {
			content: ' ';
			flex: 1 1;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			border-bottom-color: inherit;
		}
	}

	&__card {
		position: relative;
		transition: max-height 0.3s ease-in-out;
		grid-template-rows: subgrid;
		grid-row: span 9;
		gap: 10px;

		@include lt-xxs {
			width: 290px;
		}

		&[data-module-expanded='false'] {
			grid-row: span 4;
		}

		&__popular-chip {
			position: absolute;
			width: min-content;
			justify-content: center;
			display: flex;
			cursor: default;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&__image {
			height: 200px;
			width: 100%;
			object-fit: cover;
		}

		&__learn-more-button {
			position: absolute;
			left: 47%;
			transform: translate(-50%, 350%);
			width: calc(100% - 20px);
			margin: 10px;
		}

		&__subtitle-bullet {
			display: list-item;
			list-style-type: '+ ';
			list-style-position: inside;
		}

		&__feature-bullet {
			display: list-item;
			list-style-type: '• ';
			list-style-position: inside;
		}
	}
}
