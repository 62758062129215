@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

.more-info {
	&__container {
		display: flex;

		@include lt-xxs {
			flex-direction: column;
		}
	}

	&__title {
		width: 50%;

		@include lt-xxs {
			width: 100%;
		}
	}

	&__items {
		width: 50%;

		@include lt-xxs {
			width: 100%;
		}

		&__item {
			height: 106px;
		}
	}
}
