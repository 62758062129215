@import '~base/scss/vars';

.payment-modal-default {
	&__division {
		&__item {
			height: 127px;
			background-color: $white-smoke;

			&:hover {
				background-color: $very-light-pink-1;
			}
		}
	}

	&__time-period div {
		&:hover {
			background-color: $very-light-pink-1;
		}
	}

	&__user-selection div {
		&:hover {
			background-color: $very-light-pink-1;
		}
	}
}
