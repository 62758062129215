@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

.recommendation-section {
	height: 620px;
	max-width: 1232px;

	@include gt-uhd {
		overflow: hidden;
	}

	&__title {
		line-height: normal;

		@include gt-uhd {
			height: 48px;
		}
	}

	&__container {
		overflow-x: visible;
		display: flex;
		margin-top: 40px;
		margin-bottom: 40px;
		transition: opacity 500ms ease-in-out, transform 1000ms ease-in-out;
		opacity: 1;
		transform: translateX(calc(var(--page) * -50%));

		@include lt-xs {
			transform: translateX(calc(var(--page) * -100%));
		}

		@include gt-uhd {
			transform: translateX(calc(var(--page) * -404px));
		}

		&--opacity-transition {
			opacity: 0;
		}
	}

	&__item {
		height: 456px;
		background-color: $white-smoke;
		display: flex;
		flex-direction: column;
		min-width: 49%;

		@include lt-xs {
			min-width: 100%;
		}

		@include gt-uhd {
			min-width: 404px;
		}

		&:hover img {
			width: 100px;
			height: 100px;
		}

		&__upper-half {
			display: grid;
			grid-template-areas: 'inner';
			height: 50%;
		}

		&__new-pill {
			grid-area: inner;
			width: 49px;
			height: 25px;
			border-radius: 125.5px;
			background-color: $azure;
			margin: 20px 0 0 20px;
			padding-left: 10px;
		}

		&__icon {
			grid-area: inner;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				transition: width 250ms ease-in-out, height 250ms ease-in-out;
			}
		}

		&__shadow {
			grid-area: inner;
			display: flex;
			align-items: flex-end;
			height: 100%;

			&__effect {
				height: 80px;
				width: 100%;
				background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
			}
		}
	}

	&__pagination {
		justify-self: center;
		height: 29px;
	}
}
