@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

.product-page {
	&__container {
		display: grid;
		grid-template-areas: 'inner-div';
		width: 100vw;
		justify-content: center;
		overflow-x: hidden;
	}

	&__header-container {
		grid-area: inner-div;
		width: 100vw;
	}

	@include lt-xxs {
		&__container {
			grid-template-rows: auto 1fr;
		}

		&__header-container {
			grid-area: unset;
		}
	}

	&__content-container {
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		grid-area: inner-div;
		background-color: white;
		width: 100%;
		z-index: 2;
		margin-top: calc(var(--margin-base, 5px) * 95);
		border-radius: 4px;

		@include gt-uhd {
			max-width: 1332px;
			min-width: 1232px;
			gap: 120px;
		}

		@include lt-hd {
			max-width: 1332px;
			min-width: 1232px;
			gap: 120px;
		}

		@include lt-lg {
			max-width: 1232px;
			min-width: 949px;
			gap: 120px;
		}

		@include lt-md {
			max-width: 948px;
			min-width: 769px;
			gap: 80px;
			margin-top: calc(var(--margin-base, 3.75px) * 135);
		}

		@include lt-sm {
			max-width: 768px;
			min-width: 481px;
			margin-top: calc(var(--margin-base, 2.85px) * 165);
		}

		@include lt-xs {
			max-width: 480px;
			min-width: 373px;
			margin-top: calc(var(--margin-base, 2.25px) * 230);
		}

		@include lt-xxs {
			grid-area: unset;
			min-width: 270px;
			margin-top: unset;
			transform: translateY(-40px);
		}
	}
}
