@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

$empty-line-width: 10px;

.vehicle-part-search-bar {
	width: 270px;
	right: 0;
}

.vehicle-part-teaser {
	min-width: 700px;
	width: auto;
	right: 0;
	z-index: 2;
	margin-top: 3px;

	@include lt-lg {
		min-width: 500px;
	}

	&--left {
		min-width: 700px;
		left: 0;

		@include lt-sm {
			min-width: 500px;
		}
	}

	&__part-container {
		display: grid;
		margin-top: -1px;
		grid-template-columns: repeat(auto-fit, minmax($empty-line-width, 1fr));
		grid-template-areas:
			'desc purchase'
			'category purchase'
			'footnote purchase'
			'footnote faster'
			'footnote .'
			'error error';

		@extend %part;
	}

	&__ipart-container {
		display: flex;

		&__item {
			&:hover {
				background-color: $white-smoke;
			}
		}
	}

	&__ipart-modal-button {
		display: flex;
		flex-direction: row;
	}

	&__related-parts {
		display: grid !important;
		gap: 5px;
		grid-template-columns: repeat(auto-fit, minmax($empty-line-width, 1fr));
		grid-template-areas:
			'desc purchase'
			'category purchase'
			'footnote1 purchase'
			'footnote2 faster'
			'footnote .'
			'error error';

		&__packages {
			align-items: self-start;
			grid-area: footnote1;
		}

		@extend %part;
	}

	%part {
		.part {
			&__description {
				grid-area: desc;
			}

			&__category {
				grid-area: category;
			}

			&__footnotes {
				grid-area: footnote2;
				align-items: self-start;
			}

			&__purchase {
				grid-area: purchase;

				& > * {
					margin: 5px 0;
				}

				&__price {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-end;
				}

				&__shopping {
					gap: $grid-spacing-small;
					justify-content: flex-end;
					margin-top: 20px;
					display: flex;
					flex-wrap: wrap;

					&__basket {
						gap: $grid-spacing-small;
						justify-content: flex-end;
						margin-top: 20px;

						@include lt-lg {
							flex-direction: row;
							align-items: normal;
						}
					}
				}
			}

			&--error {
				grid-area: error;
				justify-content: flex-start;
			}

			&__faster-available {
				grid-area: faster;
				justify-content: flex-end;
			}

			&__related-package {
				grid-column: 1 / span 3;
				grid-row: 7 / span 1;

				@include lt-md {
					grid-row: 9 / span 1;
				}

				@include lt-xs {
					grid-row: 10 / span 1;
				}

				&__item {
					&:hover {
						background-color: $white-smoke;
					}
				}
			}
		}
	}
}
