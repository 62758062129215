@import '~base/scss/_breakpoints.scss';

.function-scope {
	grid-template-columns: 1fr 1fr;

	@include lt-xxs {
		grid-template-columns: auto;
		gap: 0;
	}
}
