@import '~base/scss/_vars.scss';
@import '~base/scss/_breakpoints.scss';

#cookie-title {
	font-size: 22px;
}

p[id='cookie description'] {
	font-weight: normal;
	font-size: 1.125rem;
}

.user-centrics-banner {
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	position: absolute !important;
	bottom: 0;
	max-width: 1000px;
	max-height: 85vh;

	@include lt-xxs {
		border-radius: 0 !important;
		bottom: 0;
		margin: auto;
		width: 100%;
	}

	&__button-container {
		display: flex;
		justify-content: flex-end;
		gap: $grid-spacing-small;
		margin-top: 21px;

		@include lt-xxs {
			gap: 0;
			flex-flow: column;
		}

		button {
			min-height: 35px;
			margin: 7px 0 7px 0;
		}
	}

	&__button {
		box-shadow: 0 1px 4px rgb(0 0 0 / 40%) !important;
		margin: 0 7px 0 7px;
		min-width: 110px;

		@include lt-xxs {
			padding: 12px 0;
			box-shadow: none !important;
			border-radius: 0;
		}
	}

	&__linkout-container {
		display: flex;
		justify-content: flex-end;
		gap: $grid-spacing-medium;
		margin-top: 12px;

		@include lt-xxs {
			gap: $grid-spacing-small;
			justify-content: flex-start;
			margin: 20px 0;

			a {
				text-decoration: underline;
			}
		}
	}
}
