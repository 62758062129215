@import '~base/scss/_vars.scss';

.import-progress-bar {
	border-radius: 12px;
	background-color: $very-light-pink-1;
	width: 100%;
	height: 10px;

	&__loading {
		background-color: $azure;
		height: 10px;
		border-radius: 12px;
		transition: width 3s;
		width: 0%;

		&--upload-stage {
			width: 25%;
		}

		&--extract-stage {
			width: 50%;
			transition: width 2s;
		}

		&--fetch-details-stage {
			width: 75%;
			transition: width 3s;
		}

		&--loading-stage {
			width: 100%;
			transition: width 2s;
		}
	}
}
