@import '~base/scss/vars';

.validation-part-list {
	&-header {
		border-radius: 4px;
		background-color: $white-two;
		display: flex;
		align-content: center;
		flex-direction: row;
	}
}
