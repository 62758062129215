@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

.faq-question {
	&__title {
		line-height: 48px;

		&__icon {
			@include lt-xs {
				width: 48px;
				height: 48px;
			}
		}
	}

	&__question-item {
		padding: 40px;
		padding-left: 30px;
		background-color: $white-smoke;

		&:hover {
			background-color: $very-light-pink-1;
		}

		@include lt-xs {
			padding: 20px;
			padding-left: 10px;
		}
	}

	&__answer-item {
		background-color: inherit;
		width: inherit;
	}
}
