@import '~base/scss/_breakpoints.scss';

.product-page-header {
	&__image-container {
		position: relative;
		height: 600px;
		background-color: #49535b;
		background-size: cover;
		background-position: center;

		@media (max-aspect-ratio: 1/1) {
			background-size: auto 600px;
		}
	}

	&__wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__container {
		position: relative;
		margin-inline: auto;
		margin-top: auto;
		padding-bottom: calc(var(--padding-base, 5px) * 35);
		z-index: 3;
	}

	&__head {
		align-items: flex-end;
		gap: 20px;

		.multiline {
			align-self: flex-end;
		}
	}

	&__title {
		align-self: center;
		line-height: 64px;
		width: 575px;
	}

	&__icon-wrapper {
		width: 108px;
		height: 108px;
	}

	&__icon {
		width: 70px;
		height: 70px;
	}

	&__body {
		@include xs {
			flex-direction: column;

			.product-page-header__action-group {
				align-self: flex-start;
				flex-direction: row;
				margin-top: 20px;
				flex-wrap: wrap;
				row-gap: 10px;
				column-gap: 10px;
			}
		}

		@include sm {
			gap: calc(var(--gap-base) * 36);
		}

		@include gt-sm {
			gap: calc(var(--gap-base) * 36);
		}
	}

	&__description {
		width: 702px;
	}

	&__action-group {
		align-self: flex-end;
		gap: 10px;
	}

	&__action {
		height: 48px;
	}

	&__purchase-container {
		transform: translateY(-100%) !important;

		&:not([data-sticky='false']) {
			transition: transform 0.3s ease-in-out;
		}

		&[data-sticky='true'] {
			box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
			background-color: white;
			height: 58px;
			left: 0 !important;
			width: 100vw !important;
			transform: translateY(0) !important;

			button {
				top: 5px;
				transform: translateY(0);

				@include lt-xxs {
					width: calc(100% - var(--padding-base) * 20);
					left: calc(var(--padding-base) * 10) !important;
				}
			}
		}

		button {
			transition: background-color 0.5s ease;
			transform: translateY(100%);
		}
	}

	&__purchase-animation {
		animation: slide-down 0.4s forwards !important;
	}

	&__custom-info-box {
		color: white;
		border-radius: 10px;
		padding: 10px;
		font-size: medium;
		width: 85%;
		margin-top: 2px;
	}

	@include lt-md {
		&__description {
			max-width: 584px;
			width: auto;
		}
	}

	@include lt-sm {
		&__container {
			padding-inline: 68px;
			padding-bottom: calc(var(--padding-base, 2.25px) * 66);
		}

		&__title {
			width: 488px;
		}

		&__description {
			width: auto;
		}

		&__action-group {
			flex-direction: column;
		}

		&__action {
			width: 280px;
		}
	}

	@include lt-xs {
		&__container {
			padding-inline: calc(var(--padding-base, 2.25px) * 20);
		}

		&__title {
			width: calc(100% - 150px);
		}

		&__action {
			width: 180px;
		}

		&__icon-wrapper {
			min-width: 80px;
			width: 80px;
			height: 80px;
		}

		&__icon {
			width: 40px;
			height: 40px;
		}
	}

	@include lt-xxs {
		&__image-container {
			background-repeat: no-repeat;
			background-size: cover;
			height: auto;
			min-height: 600px;
			display: flex;
			flex-direction: column;
		}

		&__wrapper {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			height: auto;
		}

		&__container {
			margin-top: calc(var(--padding-base) * 9);
			padding-inline: 21px;
			padding-bottom: 55px;
			flex-grow: 1;
			height: auto;
		}

		&__title {
			font-size: calc(var(--font-base) * 3);
			width: auto;
		}

		&__head {
			gap: 10px;
			align-items: flex-start;
		}

		&__body {
			flex-direction: column;
		}

		&__description {
			margin-bottom: calc(var(--margin-base) * 18);
		}

		&__action {
			width: 100%;
		}

		&__action-group {
			width: 100%;
		}
	}

	@include xs {
		&__title {
			width: 100%;
		}
	}
}

.header-default-background {
	&__watermark-icon {
		position: absolute;
		height: 100%;
		width: auto;
		right: 80px;
		opacity: 0.15;
		pointer-events: none;
		object-fit: contain;

		path {
			position: absolute;
			top: 50%;
			right: 0;
		}

		@include lt-xs {
			display: none;
		}
	}

	&__pattern-layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
	}

	&__star-pattern {
		background: url('@Static/images/rmi-default-bg-pattern.svg') repeat;
		pointer-events: none;
	}

	&__vertical-gradient {
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.9) 100%);
	}

	&__image-gradient-layer {
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), var(--black) 77%, var(--black));
	}

	&__radial-gradient {
		background-image: radial-gradient(circle at 100% 4.5%, #fff 0%, rgba(0, 0, 0, 0) 100%);
		opacity: 0.4;
		pointer-events: none;
	}

	&__horizontal-alpha-gradient {
		background-image: linear-gradient(90deg, rgba(128, 139, 148, 0) 0%, #49535b 90%, #49535b 100%);
		pointer-events: none;
	}
}
