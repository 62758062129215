@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

html {
	height: 100%;

	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;

	&:has(.modal-open) {
		overflow: clip;
	}

	.modal-container {
		@include lt-xs {
			max-height: var(--app-height);
		}

		.modal__plain {
			max-width: 100%;
			width: 100%;
		}
	}
}

html,
body {
	padding: 0;
	margin: 0;
	color: $black-two;
	position: absolute;

	&.loading {
		display: block;
		opacity: 0.75;
		z-index: 0;
		position: relative;
		background-color: $white;

		&::after {
			position: fixed;
			top: 0;
			left: 0;
			content: '';
			width: 100%;
			height: 10px;
			background-image: repeating-linear-gradient(-45deg, $greyish-brown, $black-two 11px, #666 10px, #555 10px);
			background-size: 28px 28px;
			animation: move 0.5s linear infinite;
			z-index: 1;
		}

		@keyframes move {
			0% {
				background-position: 0 0;
			}

			100% {
				background-position: 28px 0;
			}
		}
	}
}

.fouc {
	display: contents;
	position: absolute;
	margin-right: 12px;

	&::-webkit-scrollbar-thumb {
		background-color: #666;
	}
}

.products-filter__container {
	&::after {
		content: ' ';
		width: 7px;
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.01) 50%, rgba(0, 0, 0, 0) 100%);
		position: absolute;
		top: 0;
		right: -7px;
		bottom: 0;
	}
}

// Data Attributes

*[data-break-spaces='true'] {
	white-space: break-spaces;
}

*[data-disabled='true'] {
	opacity: 0.4;
	cursor: not-allowed;
}

*[data-hover-azure='true'] {
	&:hover {
		color: $azure;

		* {
			color: $azure;
		}
	}
}

*[data-hover-cerulean='true'] {
	&:hover {
		color: $cerulean;

		* {
			color: $cerulean;
		}
	}
}

*[data-visible='true'] {
	visibility: visible;
}

*[data-visible='false'] {
	visibility: hidden;
}

*[data-box='true'] {
	background: white;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

*[data-display-hidden='true'] {
	display: none;
}

*[data-display-hidden='false'] {
	display: inherit;
}

// TODO: Add this to storybook

.w-inherit {
	width: inherit;
}

* {
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;

		&:hover {
			width: 12px;
			height: 12px;
		}
	}

	&::-webkit-scrollbar-thumb {
		background-color: #bfbfbf;
		border-radius: 10px;
		transition: background-color 0.5s ease;
		width: 8px;
		height: 8px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ececec;
		border-radius: 10px;
	}

	&:hover {
		&::-webkit-scrollbar {
			width: 12px;
			height: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #666;
		}
	}
}
