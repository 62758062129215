@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

$validation-part-template: min(1.4fr) min(1.8fr) min(2.4fr) min(0.6fr) min(4.4fr);
$replaceable-part-template: min(1.8fr) min(2.4fr) min(1.2fr);

.validation-part {
	width: 100%;
	display: grid;
	align-items: center;
	grid-template-columns: $validation-part-template;
	gap: 2em;
	grid-template-areas: 'status number description quantity options';

	&--validating {
		opacity: 0.4;
	}

	&__item {
		&__status {
			grid-area: status;
		}

		&__number {
			grid-area: number;

			&--replaced {
				text-decoration: line-through;
			}
		}

		&__description {
			grid-area: description;

			&__title {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
			}
		}

		&__quantity {
			grid-area: quantity;
		}

		&__options {
			grid-area: options;
			align-items: end;

			.replaceable-box {
				right: 0;
				border-radius: 4px;
				box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.15);
				background-color: white;
				width: max-content;

				.replaceable-part {
					display: grid;
					align-items: center;
					grid-template-columns: $replaceable-part-template;
					gap: 2em;
					grid-template-areas: 'partNo partDescription replace';

					&__number {
						grid-area: partNo;
					}

					&__description {
						grid-area: partDescription;
					}

					&__replace-button {
						grid-area: replace;
					}
				}
			}
		}
	}
}
