@import '~base/scss/_vars.scss';

.file-import-modal {
	&__description {
		&__list {
			list-style: inside;
		}
	}

	&__drag-and-drop {
		border: 2px dashed $very-light-pink-3;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__info-text {
			overflow-wrap: anywhere;
		}
	}

	&__vin-selection {
		.checkbox {
			width: max-content;
		}

		.checkbox__label {
			font-size: 16px;
		}
	}
}
