@import '~base/scss/_vars.scss';

.sticky-toolbar {
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	position: sticky;
	top: 0;
	width: 100vw;
	z-index: 10;

	&__basket {
		gap: 0;

		&:hover {
			background-color: $azure;
			color: $white;
		}

		&__label {
			margin-left: 10px;
			margin-right: 5px;
		}
	}

	.retailer-dropdown {
		min-width: 300px;

		&__vehicle-info-tooltip {
			padding: 0 !important;
		}
	}

	.vin-dropdown {
		min-width: 300px;
	}

	.curtain-open &,
	.modal-open & {
		z-index: 1 !important;
	}

	.parts-validation {
		cursor: pointer;
	}
}
