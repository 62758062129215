@import '~base/scss/vars';
@import '~base/scss/_breakpoints.scss';

.product-advantages {
	&__header {
		font-weight: bold;
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 4vw;
		row-gap: 1vh;
		width: 100%;
		box-sizing: border-box;

		@include xxs {
			grid-template-columns: 1fr;
			row-gap: 0;
		}

		&__item {
			text-align: center;
			display: inline-flex;
			align-items: baseline;
			align-self: center;
			height: 100%;

			&__icon {
				padding-top: 10px;
			}

			&__text {
				display: inline-block;
				text-align: left;

				a {
					text-decoration: underline;
					color: $black-two;
				}
			}
		}
	}
}
