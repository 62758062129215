@import '~base/scss/_breakpoints.scss';
@import '~base/scss/vars';

.need-more-help {
	font-size: 16px;

	&__title {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	&__button {
		@include lt-xxs {
			width: 100%;
		}
	}
}
